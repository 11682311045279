export default {
  primary: "black",
  secondary: "#da1c4b",
  tertiary: "#D81159",
  accent: "#ffbc42",
  green: "#76B041",
  backgroundColor: "#d1d1d1",
  text: "#262626",
  // text: "black",
};
