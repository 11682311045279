import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import MainFooter from "../../components/UI/Footer";

import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import { MdAutoGraph, MdPhone, MdLabel, MdTheaterComedy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/authActions";
import colors from "../../config/colors";
import Search from "./Search";
import axios from "axios";
import { APIURL } from "../../config/vars";
import Payment from "./Payment";

const Support = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);
  const [search, setSearch] = useState("");
  const [payment, setPayment] = useState([]);
  const [searchPayment, setSearchPayment] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  const payments = payment.filter((payment) =>
    payment.customerName.toLowerCase().includes(search.toLowerCase())
  );

  const auth = useSelector((state) => state.auth);

  const getPendingPayments = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    await axios
      .get(`${APIURL}pendingpayments?createdAt[gt]=${date}`, config)
      .then((res) => {
        setPayment(res.data.data);
        setSearchPayment(res.data.data);
      });
  };

  useEffect(() => {
    getPendingPayments();
  }, [date]);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const authBodyLinks = [
    {
      title: "Sales",
      to: "/",
      icon: <MdAutoGraph style={globalStyles.icon} />,
    },
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
    {
      title: "Events",
      to: "/events",
      icon: <MdTheaterComedy style={globalStyles.icon} />,
    },
    {
      title: "Support",
      to: "/support",
      icon: <MdPhone style={globalStyles.icon} />,
    },
  ];

  return (
    <div>
      <Navbar onClick={handleSideMenu} />
      {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
      <SideMenu
        isOpen={toggleSideMenu}
        bodyLinks={authBodyLinks}
        footer={<Footer />}
      />
      <div style={styles.container}>
        <Search search={search} handleSearchChange={setSearch} />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          style={{
            marginTop: 10,
            width: 180,
            borderRadius: 5,
            border: "none",
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        />
        <div style={{ paddingRight: 20, display: "flex", flexWrap: "wrap" }}>
          {payments.length ? (
            <React.Fragment>
              {payments.map((payment, i) => {
                return (
                  <Payment
                    key={i}
                    auth={auth}
                    payment={payment}
                    updatePayments={getPendingPayments}
                  />
                );
              })}
            </React.Fragment>
          ) : null}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    background: colors.text,
    minHeight: 700,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingTop: 60,
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    marginTop: 56,
  },
};

export default Support;
