import React, { useState, useEffect } from "react";
import axios from "axios";
import colors from "../../config/colors";
import { APIURL } from "../../config/vars";
import { useSelector } from "react-redux";

export const TicketSales = () => {
  const auth = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  const [ticketSales, setTicketSales] = useState([]);

  const getTicketSales = async () => {
    await axios.get(`${APIURL}sales`, config).then((res) => {
      setTicketSales(res.data.data.ticketSalesData);
    });
  };

  useEffect(() => {
    getTicketSales();
  }, []);

  if (!ticketSales.length) {
    return (
      <div>
        <div>
          <div style={{ color: colors.secondary }}>Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ color: "white", maxWidth: "100vw", overflow: "hidden" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {ticketSales.map((sale, i) => {
          return (
            <div
              key={i}
              style={{
                backgroundColor: "white",
                color: "black",
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
                paddingTop: 8,
                marginTop: 8,
                borderRadius: 4,
                width: 250,
                marginRight: 8,
              }}
            >
              <h4 style={{ margin: 0, lineHeight: 1, marginBottom: 16 }}>
                {sale.ticketName}
              </h4>
              <div style={{ color: colors.secondary, fontWeight: "bold" }}>
                {sale.sales} / {sale.totalTicketsForSale}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
