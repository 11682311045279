export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_TICKETS = "GET_TICKETS";
export const TICKETS_TO_PURCHASE = "TICKETS_TO_PURCHASE";
export const SET_FINAL_TOTAL = "SET_FINAL_TOTAL";
