import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import MainFooter from "../../components/UI/Footer";
import Input from "../../components/UI/Input";
import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import {
  MdAutoGraph,
  MdPhone,
  MdLabel,
  MdTheaterComedy,
  MdDelete,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/authActions";
import colors from "../../config/colors";
import axios from "axios";
import { APIURL } from "../../config/vars";
import Modal from "../../components/UI/Modal";

const Events = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState("");
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
    name: "",
    _id: "",
  });

  const auth = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  const getEvents = async () => {
    await axios
      .get(`${APIURL}events`)
      .then((res) => {
        setEvents(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const authBodyLinks = [
    {
      title: "Sales",
      to: "/",
      icon: <MdAutoGraph style={globalStyles.icon} />,
    },
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
    {
      title: "Events",
      to: "/events",
      icon: <MdTheaterComedy style={globalStyles.icon} />,
    },
    {
      title: "Support",
      to: "/support",
      icon: <MdPhone style={globalStyles.icon} />,
    },
  ];

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    const body = {
      name: event,
    };

    await axios
      .post(`${APIURL}events`, body, config)
      .then(() => {
        getEvents();
        setEvent("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteModalBody = () => {
    const handleDelete = async () => {
      await axios
        .delete(`${APIURL}events/${selectedEvent._id}`, config)
        .then(() => {
          getEvents();
          setSelectedEvent({
            name: "",
            _id: "",
          });
          setToggleDeleteModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div>
        <p>Are you sure you want to delete {selectedEvent.name}?</p>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            title="Delete"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleDelete}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setToggleDeleteModal(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        header={<h1>Delete {selectedEvent.name}</h1>}
        body={<DeleteModalBody />}
        close={() => setToggleDeleteModal(!toggleDeleteModal)}
        isOpen={toggleDeleteModal}
      />
      <div>
        <Navbar onClick={handleSideMenu} />
        {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
        <SideMenu
          isOpen={toggleSideMenu}
          bodyLinks={authBodyLinks}
          footer={<Footer />}
        />
        <div style={styles.container}>
          <div style={{ color: colors.primary }}>
            <h1 style={{ color: colors.secondary }}>Events</h1>
            <form
              style={{ display: "flex", flexDirection: "column", width: 250 }}
              onSubmit={handleCreateEvent}
            >
              <Input
                type="text"
                placeholder="Event name"
                style={{ marginBottom: 20 }}
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                required
              />
              <Button
                title="Create Event"
                type="submit"
                style={{ backgroundColor: colors.secondary }}
              />
            </form>
            <div style={{ width: 250 }}>
              {events.map((event) => {
                return (
                  <div
                    key={event._id}
                    style={{
                      background: "white",
                      paddingLeft: 10,
                      marginTop: 20,
                      paddingTop: "1px",
                      paddingBottom: "1px",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ font: "bold" }}>{event.name}</p>
                    <MdDelete
                      style={{
                        ...globalStyles.icon,
                        marginTop: 15,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        setSelectedEvent({
                          name: event.name,
                          _id: event._id,
                        });
                        setToggleDeleteModal(!toggleDeleteModal);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    background: colors.text,
    minHeight: 700,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingTop: 60,
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    marginTop: 56,
  },
};

export default Events;
