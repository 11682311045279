import React, { useState, useEffect } from "react";
import axios from "axios";
import colors from "../../config/colors";
import { APIURL } from "../../config/vars";
import { useSelector } from "react-redux";

export const Demographics = () => {
  const auth = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };
  const [demographics, setDemographics] = useState(null);

  const getDemographics = async () => {
    await axios.get(`${APIURL}demographics`, config).then((res) => {
      setDemographics(res.data.data);
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    getDemographics();
  }, []);

  if (!demographics) {
    return (
      <div>
        <div>
          <div style={{ color: colors.secondary }}>Loading...</div>
        </div>
      </div>
    );
  }

  const totalAge = Object.values(demographics.age).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const totalCountries = Object.values(demographics.countries).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const totalGender =
    demographics.males + demographics.females + demographics.other;

  return (
    <div>
      <h1 style={{ color: colors.secondary, lineHeight: 0, marginTop: 40 }}>
        Demographics
      </h1>
      <div>
        <p style={{ fontSize: 24 }}>
          <u>Age</u>
        </p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(demographics.age).map(([key, value]) => (
            <div
              style={{
                marginRight: 10,
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 5,
                borderRadius: 5,
                color: "black",
                background: "white",
                width: "10%",
              }}
              key={key}
            >
              <p>
                <u
                  style={{
                    color: colors.secondary,
                    fontWeight: "bold",
                    marginRight: 5,
                  }}
                >{`${key}:`}</u>
                <br />
                {` ${value}`} - ({((value / totalAge) * 100).toFixed(2)}%)
              </p>
            </div>
          ))}
        </div>
        <p style={{ fontSize: 24 }}>
          <u>Countries</u>
        </p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(demographics.countries).map(([key, value]) => (
            <div
              style={{
                marginRight: 10,
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 5,
                borderRadius: 5,
                color: "black",
                background: "white",
                width: "10%",
              }}
              key={key}
            >
              <p>
                <u
                  style={{
                    color: colors.secondary,
                    fontWeight: "bold",
                    marginRight: 5,
                  }}
                >{`${key}:`}</u>
                <br />
                {` ${value}`} - ({((value / totalCountries) * 100).toFixed(2)}%)
              </p>
            </div>
          ))}
        </div>
        <p style={{ fontSize: 24 }}>
          <u>Gender</u>
        </p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <p
            style={{
              marginRight: 10,
              marginBottom: 10,
              paddingLeft: 10,
              padding: 10,
              paddingRight: 5,
              borderRadius: 5,
              color: "black",
              background: "white",
              width: "10%",
            }}
          >
            <u
              style={{
                color: colors.secondary,
                fontWeight: "bold",
                marginRight: 5,
              }}
            >{`Male:`}</u>
            <br />
            {` ${demographics.males}`} - (
            {((demographics.males / totalGender) * 100).toFixed(2)}%)
          </p>
          <p
            style={{
              marginRight: 10,
              marginBottom: 10,
              paddingLeft: 10,
              padding: 10,
              paddingRight: 5,
              borderRadius: 5,
              color: "black",
              background: "white",
              width: "10%",
            }}
          >
            <u
              style={{
                color: colors.secondary,
                fontWeight: "bold",
                marginRight: 5,
              }}
            >{`Female:`}</u>
            <br />
            {` ${demographics.females}`} - (
            {((demographics.females / totalGender) * 100).toFixed(2)}%)
          </p>
          <p
            style={{
              marginRight: 10,
              marginBottom: 10,
              paddingLeft: 10,
              padding: 10,
              paddingRight: 5,
              borderRadius: 5,
              color: "black",
              background: "white",
              width: "10%",
            }}
          >
            <u
              style={{
                color: colors.secondary,
                fontWeight: "bold",
                marginRight: 5,
              }}
            >{`Other:`}</u>
            <br />
            {` ${demographics.other}`} - (
            {((demographics.other / totalGender) * 100).toFixed(2)}%)
          </p>
        </div>
      </div>
    </div>
  );
};
