import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import MainFooter from "../../components/UI/Footer";

import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import { MdAutoGraph, MdPhone, MdLabel, MdTheaterComedy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/authActions";
import colors from "../../config/colors";
import { TicketSales } from "./TicketSales";
import { Demographics } from "./Demographics";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const auth = useSelector((state) => state.auth);

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const authBodyLinks = [
    {
      title: "Sales",
      to: "/",
      icon: <MdAutoGraph style={globalStyles.icon} />,
    },
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
    {
      title: "Events",
      to: "/events",
      icon: <MdTheaterComedy style={globalStyles.icon} />,
    },
    {
      title: "Support",
      to: "/support",
      icon: <MdPhone style={globalStyles.icon} />,
    },
  ];

  return (
    <div>
      <div>
        <Navbar onClick={handleSideMenu} />
        {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
        <SideMenu
          isOpen={toggleSideMenu}
          bodyLinks={authBodyLinks}
          footer={<Footer />}
        />
        <div style={styles.container}>
          <div style={styles.content}>
            <h1 style={{ color: colors.secondary }}>Sales</h1>
            <TicketSales />
            <Demographics />
          </div>
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    background: colors.text,
    minHeight: 700,
    color: "white",
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingTop: 60,
  },
};

export default Home;
