import React, { useState, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Backdrop from "../../components/UI/Backdrop";
import SideMenu from "../../components/UI/SideMenu";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import MainFooter from "../../components/UI/Footer";

import globalStyles from "../../config/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import {
  MdAutoGraph,
  MdPhone,
  MdLabel,
  MdTheaterComedy,
  MdCreate,
  MdDelete,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/authActions";
import colors from "../../config/colors";
import axios from "axios";
import { APIURL } from "../../config/vars";
import Modal from "../../components/UI/Modal";

const Tickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleCreateModal, setToggleCreateModal] = useState(false);
  const [toggleCreateComplimentaryModal, setToggleCreateComplimentaryModal] =
    useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({
    _id: "",
    viewName: "",
    isActive: false,
    totalForSale: 0,
    cost: 0,
  });

  const getTickets = async () => {
    await axios
      .get(`${APIURL}tickets`)
      .then((res) => {
        setTickets(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTickets();
  }, []);

  const handleSideMenu = () => {
    setToggleSideMenu(!toggleSideMenu);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate.push(`/login`);
  };

  const auth = useSelector((state) => state.auth);

  const Footer = () => {
    return (
      <Button
        style={styles.footerBtn}
        title="Logout"
        handlePress={handleLogout}
      />
    );
  };

  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  const ModalBody = () => {
    const [updateViewName, setUpdateViewName] = useState(
      selectedTicket.viewName
    );
    const [updateIsActive, setUpdateIsActive] = useState(
      selectedTicket.isActive
    );
    const [updateTotalForSale, setUpdateTotalForSale] = useState(
      selectedTicket.totalForSale
    );
    const [updateCost, setUpdateCost] = useState(selectedTicket.cost);
    const [error, setError] = useState("");

    const handleUpdate = async () => {
      if (!updateViewName) {
        setError("Please enter a name");
        return;
      }
      if (!updateTotalForSale) {
        setError("Please enter how many tickets are available");
        return;
      }
      if (!updateCost) {
        setError("Please enter a price");
        return;
      }
      await axios
        .put(
          `${APIURL}tickets/${selectedTicket._id}`,
          {
            viewName: updateViewName,
            isActive: updateIsActive,
            totalForSale: updateTotalForSale,
            cost: updateCost,
          },
          config
        )
        .then(() => {
          getTickets();
          setSelectedTicket({
            _id: "",
            viewName: "",
            isActive: false,
            totalForSale: 0,
            cost: 0,
          });
          setUpdateViewName("");
          handleToggleModal();
        })
        .catch((err) => {
          console.log(err);
          setError("Error updating ticket");
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    };

    return (
      <div>
        <p>Ticket name:</p>
        <Input
          value={updateViewName}
          onChange={(e) => {
            setUpdateViewName(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
        />
        <div>
          <p>Active:</p>
          <select
            value={updateIsActive.toString()}
            onChange={(e) => setUpdateIsActive(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
        <p>Price:</p>
        <Input
          value={updateCost}
          onChange={(e) => {
            setUpdateCost(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
          type="number"
        />
        <p>Total for sale:</p>
        <Input
          value={updateTotalForSale}
          onChange={(e) => {
            setUpdateTotalForSale(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
          type="number"
        />
        <div>
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            title="Update"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleUpdate}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setSelectedTicket({
                _id: "",
                viewName: "",
                isActive: false,
                totalForSale: 0,
                cost: 0,
              });
              setUpdateViewName("");
              handleToggleModal();
            }}
          />
        </div>
      </div>
    );
  };

  const CreateComplimentaryModalBody = () => {
    const [customerName, setCustomerName] = useState("");
    const [ticket, setTicket] = useState("");
    const [event, setEvent] = useState("");
    const [error, setError] = useState("");
    const [events, setEvents] = useState([]);
    const [currentTickets] = useState(tickets || []);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    const body = {
      purchase: {
        customerName,
        ticket,
        event,
        status: "paid",
        checkedIn: false,
      },
    };

    const getEvents = async () => {
      await axios
        .get(`${APIURL}events`)
        .then((res) => {
          setEvents(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getEvents();
    }, []);

    const handleCreate = async () => {
      if (!customerName) {
        setError("Please enter a name");
        return;
      }
      if (!ticket) {
        setError("Please select a ticket");
        return;
      }

      if (!event) {
        setError("Please select an event");
        return;
      }

      setLoading(true);

      const checkTicket = await axios.post(`${APIURL}check`, [
        {
          ticket,
          amount: 1,
          total: tickets.find((t) => t._id === ticket).cost,
        },
      ]);

      if (!checkTicket.data.data.allAvailable) {
        setError("Ticket is not available");
        setLoading(false);
        return;
      }

      await axios
        .post(`${APIURL}compticket`, body, config)
        .then((res) => {
          setCustomerName("");
          setError("");
          console.log(res.data.data);
          setLink(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError("Error creating complimentary ticket");
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    };

    return (
      <div>
        <p>Customer name:</p>
        <Input
          value={customerName}
          onChange={(e) => {
            setCustomerName(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
        />
        <div>
          <p>Ticket Type:</p>
          <select
            value={ticket}
            onChange={(e) => setTicket(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="">Select a ticket type</option>
            {currentTickets.map((ticket) => {
              return <option value={ticket._id}>{ticket.viewName}</option>;
            })}
          </select>
        </div>
        <div>
          <p>Event:</p>
          <select
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="">Select an event</option>
            {events.map((event) => {
              return <option value={event.name}>{event.name}</option>;
            })}
          </select>
        </div>
        {link && (
          <div style={{ paddingTop: 10 }}>
            <a href={`${link}`} target="_blank" rel="noreferrer">
              Download Complimentary Ticket
            </a>
          </div>
        )}
        <div>
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        </div>
        <div style={{ marginTop: 20 }}>
          {loading ? (
            "Loading..."
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                title="Create"
                style={{ backgroundColor: colors.secondary, marginRight: 10 }}
                handlePress={handleCreate}
              />
              <Button
                title="Cancel"
                style={{ backgroundColor: colors.text }}
                handlePress={() => {
                  setCustomerName("");
                  setTicket("");
                  setError("");
                  setEvent("");
                  setToggleCreateComplimentaryModal(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const CreateModalBody = () => {
    const [createViewName, setCreateViewName] = useState("");
    const [createIsActive, setCreateIsActive] = useState(true);
    const [createTotalForSale, setCreateTotalForSale] = useState(0);
    const [createCost, setCreateCost] = useState(0);
    const [event, setEvent] = useState("");
    const [error, setError] = useState("");
    const [events, setEvents] = useState([]);

    const getEvents = async () => {
      await axios
        .get(`${APIURL}events`)
        .then((res) => {
          setEvents(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getEvents();
    }, []);

    const handleCreate = async () => {
      if (!createViewName) {
        setError("Please enter a name");
        return;
      }
      if (!createTotalForSale) {
        setError("Please enter how many tickets are available");
        return;
      }
      if (!createCost) {
        setError("Please enter a price");
        return;
      }
      if (!event) {
        setError("Please select an event");
        return;
      }
      await axios
        .post(
          `${APIURL}tickets`,
          {
            viewName: createViewName,
            isActive: createIsActive,
            totalForSale: createTotalForSale,
            cost: createCost,
            event: event,
          },
          config
        )
        .then(() => {
          getTickets();
          setCreateViewName("");
          setCreateIsActive(true);
          setCreateTotalForSale(0);
          setCreateCost(0);
          setEvent("");
          setToggleCreateModal(false);
        })
        .catch((err) => {
          console.log(err);
          setError("Error creating ticket");
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    };

    return (
      <div>
        <p>Ticket name:</p>
        <Input
          value={createViewName}
          onChange={(e) => {
            setCreateViewName(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
        />
        <div>
          <p>Active:</p>
          <select
            value={createIsActive.toString()}
            onChange={(e) => setCreateIsActive(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
        <p>Price:</p>
        <Input
          value={createCost}
          onChange={(e) => {
            setCreateCost(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
          type="number"
        />
        <p>Total for sale:</p>
        <Input
          value={createTotalForSale}
          onChange={(e) => {
            setCreateTotalForSale(e.target.value);
          }}
          style={{ width: "100%", padding: 10, border: "none" }}
          type="number"
        />
        <div>
          <p>Event:</p>
          <select
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="">Select an event</option>
            {events.map((event) => {
              return <option value={event.name}>{event.name}</option>;
            })}
          </select>
        </div>
        <div>
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            title="Create"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleCreate}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setCreateViewName("");
              setCreateIsActive(true);
              setCreateTotalForSale(0);
              setCreateCost(0);
              setEvent("");
              setToggleCreateModal(false);
            }}
          />
        </div>
      </div>
    );
  };

  const DeleteModalBody = () => {
    const handleDelete = async () => {
      await axios
        .delete(`${APIURL}tickets/${selectedTicket._id}`, config)
        .then(() => {
          getTickets();
          setSelectedTicket({
            _id: "",
            viewName: "",
            isActive: false,
            totalForSale: 0,
            cost: 0,
          });
          setToggleDeleteModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div style={{ backgroundColor: "white" }}>
        <p>Are you sure you want to delete {selectedTicket.viewName}?</p>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            title="Delete"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleDelete}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setToggleDeleteModal(false);
            }}
          />
        </div>
      </div>
    );
  };

  const authBodyLinks = [
    {
      title: "Sales",
      to: "/",
      icon: <MdAutoGraph style={globalStyles.icon} />,
    },
    {
      title: "Tickets",
      to: "/tickets",
      icon: <MdLabel style={globalStyles.icon} />,
    },
    {
      title: "Events",
      to: "/events",
      icon: <MdTheaterComedy style={globalStyles.icon} />,
    },
    {
      title: "Support",
      to: "/support",
      icon: <MdPhone style={globalStyles.icon} />,
    },
  ];

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  return (
    <div>
      <Modal
        header={<h1>Update: {selectedTicket.viewName}</h1>}
        body={<ModalBody />}
        close={handleToggleModal}
        isOpen={toggleModal}
      />
      <Modal
        header={<h1>Create Ticket</h1>}
        body={<CreateModalBody />}
        close={() => setToggleCreateModal(!toggleCreateModal)}
        isOpen={toggleCreateModal}
      />
      <Modal
        header={<h1>Create Complimentary Ticket</h1>}
        body={<CreateComplimentaryModalBody />}
        close={() =>
          setToggleCreateComplimentaryModal(!toggleCreateComplimentaryModal)
        }
        isOpen={toggleCreateComplimentaryModal}
      />
      <Modal
        header={<h1>Delete {selectedTicket.viewName}</h1>}
        body={<DeleteModalBody />}
        close={() => setToggleDeleteModal(!toggleDeleteModal)}
        isOpen={toggleDeleteModal}
      />
      <div>
        <Navbar onClick={handleSideMenu} />
        {toggleSideMenu ? <Backdrop onClick={handleSideMenu} /> : null}
        <SideMenu
          isOpen={toggleSideMenu}
          bodyLinks={authBodyLinks}
          footer={<Footer />}
        />
        <div style={styles.container}>
          <div style={{ color: colors.secondary }}>
            <h1>Tickets</h1>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <button
                style={{
                  padding: 10,
                  color: colors.secondary,
                  background: "transparent",
                  borderWidth: "2px",
                  borderColor: colors.secondary,
                  borderRadius: 5,
                  marginRight: 10,
                }}
                onClick={() => setToggleCreateModal(true)}
              >
                Create Ticket
              </button>
              <button
                style={{
                  padding: 10,
                  color: colors.secondary,
                  background: "transparent",
                  borderWidth: "2px",
                  borderColor: colors.secondary,
                  borderRadius: 5,
                }}
                onClick={() => setToggleCreateComplimentaryModal(true)}
              >
                Create Complimentary Ticket
              </button>
            </div>
            <div
              style={{ display: "flex", flexWrap: "wrap", paddingRight: 60 }}
            >
              {tickets.map((ticket) => {
                return (
                  <div
                    key={ticket._id}
                    style={{
                      borderRadius: 5,
                      background: "white",
                      padding: 8,
                      width: 300,
                      marginBottom: 10,
                      marginRight: 10,
                      color: colors.text,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div>
                          <div style={{ fontWeight: "bold" }}>Name:</div>{" "}
                          {ticket.viewName}
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <div style={{ fontWeight: "bold" }}>Event:</div>{" "}
                          {ticket.event}
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <div style={{ fontWeight: "bold" }}>Active:</div>{" "}
                          {ticket.isActive ? "true" : "false"}
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <div style={{ fontWeight: "bold" }}>Price:</div> N${" "}
                          {ticket.cost.toFixed(2)}
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <div style={{ fontWeight: "bold" }}>
                            Total for sale:
                          </div>{" "}
                          {ticket.totalForSale}
                        </div>
                      </div>
                      <div>
                        <MdCreate
                          style={globalStyles.icon}
                          onClick={() => {
                            setSelectedTicket({
                              _id: ticket._id,
                              viewName: ticket.viewName,
                              isActive: ticket.isActive,
                              totalForSale: ticket.totalForSale,
                              cost: ticket.cost,
                            });
                            handleToggleModal();
                          }}
                        />
                        <MdDelete
                          style={globalStyles.icon}
                          onClick={() => {
                            setSelectedTicket({
                              _id: ticket._id,
                              viewName: ticket.viewName,
                              isActive: ticket.isActive,
                              totalForSale: ticket.totalForSale,
                              cost: ticket.cost,
                            });
                            setToggleDeleteModal(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    background: colors.text,
    minHeight: 700,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingTop: 60,
  },
  footerBtn: {
    boxShadow: "none",
  },
  content: {
    marginTop: 56,
  },
};

export default Tickets;
