import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config/vars";
import colors from "../../config/colors";
import { MdCreate } from "react-icons/md";
import globalStyles from "../../config/globalStyles";
import Modal from "../../components/UI/Modal";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";

const Purchase = ({ purchase, auth, getPurchases }) => {
  const [ticket, setTicket] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  const getTicket = async () => {
    await axios
      .get(`${APIURL}tickets?_id=${purchase.ticket}`, config)
      .then((res) => {
        setTicket(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTicket();
  }, [purchase]);

  const ModalBody = () => {
    const [ticketCustomerName, setTicketCustomerName] = useState(
      purchase.customerName
    );
    const [error, setError] = useState("");
    const [tickets, setTickets] = useState([]);
    const [updatedTicket, setUpdatedTicket] = useState("");

    const getTickets = async () => {
      await axios
        .get(`${APIURL}tickets`)
        .then((res) => {
          setTickets(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getTickets();
    }, []);

    const handleUpdate = async () => {
      setError("");
      if (ticketCustomerName === "") {
        setError("Customer name is required");
        return;
      }

      const body = {
        customerName: ticketCustomerName,
        ticket: updatedTicket,
      };

      await axios
        .put(`${APIURL}purchases/${purchase._id}`, body, config)
        .then((res) => {
          if (res.data.success) {
            getPurchases();
            setToggleModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError("Something went wrong");
        });
    };

    if (!tickets.length) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <p>Customer Name</p>
          <Input
            value={ticketCustomerName}
            onChange={(e) => setTicketCustomerName(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              marginBottom: 10,
            }}
          />
          <p>Change Ticket</p>
          <select
            value={updatedTicket}
            onChange={(e) => setUpdatedTicket(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              borderRadius: 5,
              backgroundColor: "#f1f1f1",
            }}
          >
            <option value="">Select a ticket</option>
            {tickets.map((ticket) => {
              return <option value={ticket._id}>{ticket.viewName}</option>;
            })}
          </select>
        </div>
        <div style={{ color: "red", textAlign: "center", marginBottom: 10 }}>
          {error}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Update"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleUpdate}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setToggleModal(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{ color: colors.primary }}>
      <Modal
        header={<h1>Update: {purchase.customerName}'s ticket</h1>}
        body={<ModalBody />}
        close={() => setToggleModal(false)}
        isOpen={toggleModal}
      />
      <span style={{ fontWeight: "bold" }}>Name:</span> {purchase.customerName}{" "}
      <br /> <span style={{ fontWeight: "bold" }}>Event:</span> {purchase.event}{" "}
      <br /> <span style={{ fontWeight: "bold" }}>Ticket:</span>{" "}
      {ticket?.viewName}
      <MdCreate
        style={{ ...globalStyles.icon, marginLeft: 10, marginBottom: -5 }}
        onClick={() => setToggleModal(true)}
      />
      <br />
      <br />
    </div>
  );
};

export default Purchase;
