import React from "react";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import colors from "../../config/colors";
import { useMediaPredicate } from "react-media-hook";
const Search = ({ search, handleSearchChange, handleSearch }) => {
  const biggerThan600 = useMediaPredicate("(min-width: 600px)");

  return (
    <div style={biggerThan600 ? styles.container : styles.containerSmall}>
      <Input
        value={search}
        onChange={(e) => {
          handleSearchChange(e.target.value);
        }}
        type="text"
        placeholder="Search customer"
        style={styles.input}
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  containerSmall: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  button: {
    backgroundColor: colors.secondary,
    color: "black",
    height: 40,
    width: 100,
    marginLeft: 10,
  },
  buttonSmall: {
    backgroundColor: colors.secondary,
    color: "black",
    height: 40,
    width: 100,
    marginTop: 10,
  },
  input: {
    width: 200,
  },
};

export default Search;
