import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config/vars";
import colors from "../../config/colors";
import moment from "moment";
import Purchase from "./Purchase";
import { MdInput, MdCreate } from "react-icons/md";
import globalStyles from "../../config/globalStyles";
import Modal from "../../components/UI/Modal";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";

const body = {
  status: "paid",
};

const Payment = ({ payment, auth, updatePayments }) => {
  const [purchases, setPurchases] = useState([]);
  const [verifyPaymentResult, setVerifyPaymentResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [status, setStatus] = useState(payment.status);
  const [toggleModal, setToggleModal] = useState(false);

  const updateAndGenerateTicket = async () => {
    setUpdateLoading(true);
    await axios
      .put(`${APIURL}pendingpayments/${payment._id}`, body)
      .then((res) => {
        if (res.data.success) {
          setDownloadLinks(res.data.links);
          setStatus("paid");
        }
      });

    setUpdateLoading(false);
  };

  const verifyPayment = async (id) => {
    setLoading(true);
    const result = await axios.get(`${APIURL}check/${id}`);

    if (
      result.data.data.status === true ||
      result.data.data.status === "success"
    ) {
      setLoading(false);
      setVerifyPaymentResult("Payment is verified");
      return;
    }

    setLoading(false);
    setVerifyPaymentResult("No payment found");
  };

  const config = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  const getPurchases = async () => {
    let getPurchases = [];

    for (let i = 0; i < payment.purchases.length; i++) {
      const purchase = payment.purchases[i];
      await axios
        .get(`${APIURL}purchases?_id=${purchase}`, config)
        .then((res) => {
          getPurchases.push(res.data.data[0]);

          if (i + 1 === payment.purchases.length) {
            setPurchases(getPurchases);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setPurchases([]);
    getPurchases();
  }, [payment]);

  const ModalBody = () => {
    const [paymentCustomerEmail, setPaymentCustomerEmail] = useState(
      payment.customerEmail
    );
    const [error, setError] = useState("");

    const handleUpdate = async () => {
      setError("");
      if (paymentCustomerEmail === "") {
        setError("Customer name is required");
        return;
      }

      if (!paymentCustomerEmail.includes("@")) {
        setError("Invalid email");
        return;
      }

      const body = {
        customerEmail: paymentCustomerEmail,
      };

      await axios
        .put(`${APIURL}pendingpayments/${payment._id}`, body, config)
        .then((res) => {
          if (res.data.success) {
            updatePayments();
            setToggleModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError("Something went wrong");
        });
    };

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <p>Email</p>
          <Input
            value={paymentCustomerEmail}
            onChange={(e) => setPaymentCustomerEmail(e.target.value)}
            style={{
              width: "100%",
              padding: 10,
              border: "none",
              marginBottom: 10,
            }}
            type="email"
          />
        </div>
        <div style={{ color: "red", textAlign: "center", marginBottom: 10 }}>
          {error}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Update"
            style={{ backgroundColor: colors.secondary, marginRight: 10 }}
            handlePress={handleUpdate}
          />
          <Button
            title="Cancel"
            style={{ backgroundColor: colors.text }}
            handlePress={() => {
              setToggleModal(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <Modal
        header={<h1>Update Email</h1>}
        body={<ModalBody />}
        close={() => setToggleModal(false)}
        isOpen={toggleModal}
      />
      <p style={{ color: colors.primary }}>
        Customer:
        <div style={{ fontWeight: "bold" }}>{payment.customerName}</div>
      </p>
      <p style={{ color: colors.primary }}>
        Email:
        <div style={{ fontWeight: "bold", display: "flex" }}>
          {payment.customerEmail}
          <MdCreate
            style={{ ...globalStyles.icon, marginLeft: 15 }}
            onClick={() => setToggleModal(true)}
          />
        </div>
      </p>
      <p style={{ color: colors.primary }}>
        Cell Number:
        <div style={{ fontWeight: "bold" }}>
          {payment.customerContactNumber}
        </div>
      </p>
      <p style={{ color: colors.primary }}>
        Status:
        <div style={{ fontWeight: "bold", display: "flex" }}>
          {status}
          {updateLoading && <div style={{ marginLeft: 15 }}>Loading...</div>}
          {!updateLoading && (
            <MdInput
              style={{ ...globalStyles.icon, marginLeft: 15 }}
              onClick={updateAndGenerateTicket}
            />
          )}
        </div>
      </p>
      {downloadLinks.length > 0 && (
        <div
          style={{ marginBottom: 15, display: "flex", flexDirection: "column" }}
        >
          {downloadLinks.map((link, i) => (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: colors.primary,
                fontWeight: "bold",
                marginTop: 5,
              }}
            >
              Download Ticket {i + 1}
            </a>
          ))}
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          style={{
            height: 30,
            border: "none",
            color: "white",
            background: colors.primary,
            borderRadius: 5,
          }}
          onClick={() => verifyPayment(payment._id)}
        >
          Verify Payment
        </button>
        <p
          style={{
            color: colors.primary,
            marginLeft: 5,
            fontWeight: "bold",
            lineHeight: 0,
          }}
        >
          {loading ? "Loading..." : verifyPaymentResult}
        </p>
      </div>
      <p style={{ color: colors.primary }}>
        Date of Purchase:
        <div style={{ fontWeight: "bold" }}>
          {moment(payment.createdAt).calendar()}
        </div>
      </p>
      <p style={{ color: colors.primary }}>
        Ref:
        <div style={{ fontWeight: "bold" }}>{payment._id}</div>
      </p>
      <p style={{ color: colors.primary }}>
        <u style={{ fontWeight: "bold" }}>Tickets:</u>
        {purchases.map((purchase, i) => {
          return (
            <Purchase
              key={i}
              purchase={purchase}
              auth={auth}
              getPurchases={getPurchases}
              index={i + 1}
            />
          );
        })}
      </p>
      <p style={{ color: colors.primary }}>
        Total:
        <div style={{ fontWeight: "bold" }}>N${payment.total}</div>
      </p>
    </div>
  );
};

const styles = {
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    background: "white",
    marginTop: 20,
    borderRadius: 5,
    marginRight: 10,
    width: 300,
  },
};

export default Payment;
