import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "./actions/authActions";

import NotFound from "./views/notFound/NotFound";
import PrivateRoute from "./views/auth/PrivateRoute";
import Home from "./views/home/Home";
import Checkin from "./views/checkin/Checkin";
import Events from "./views/events/Events";
import Support from "./views/support/Support";
import Tickets from "./views/tickets/Tickets";
import Auth from "./views/auth/Auth";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <React.Fragment>
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route element={<Home />} path="/" />
            </Route>
            <Route path="/checkin/:id" element={<PrivateRoute />}>
              <Route element={<Checkin />} path="/checkin/:id" />
            </Route>
            <Route path="/events" element={<PrivateRoute />}>
              <Route element={<Events />} path="/events" />
            </Route>
            <Route path="/support" element={<PrivateRoute />}>
              <Route element={<Support />} path="/support" />
            </Route>
            <Route path="/tickets" element={<PrivateRoute />}>
              <Route element={<Tickets />} path="/tickets" />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Fragment>
      </Router>
    </div>
  );
}

export default App;
