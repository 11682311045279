import {
  GET_TICKETS,
  TICKETS_TO_PURCHASE,
  SET_FINAL_TOTAL,
} from "../actions/types";

const initialState = {
  tickets: [],
  ticketsToPurchase: [],
  finalTotal: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
    case TICKETS_TO_PURCHASE:
      return {
        ...state,
        ticketsToPurchase: action.payload,
      };
    case SET_FINAL_TOTAL:
      return {
        ...state,
        finalTotal: action.payload,
      };
    default:
      return state;
  }
}
